
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI","Helvetica", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.pt-jumbotron{
  padding-top: 8rem;
}
.btn-link{
  text-decoration: none !important;
}

.text-highlight{
  text-decoration: none;
  /* background-color: #F8EAD0; */
  color: black;
}
.text-main{
  color:#5B5C07;
}
.link-cmu{
  color:#a81a1a;
}

.text-introduction{
 font-size: 1.125rem;
}
.rounded-block-link:hover{
  z-index: 2;
  -webkit-transition: all 150ms ease-in-out;
  -webkit-transform: scale(1.05);
  -ms-transition: all 150ms ease-in-out;
  -ms-transform: scale(1.05);   
  -moz-transition: all 150ms ease-in-out;
  -moz-transform: scale(1.05);
  transition: all 150ms ease-in-out;
  transform: scale(1.05);
  color: white !important;
  font-weight: 600;
}

.md-navbar{
  display: none;
}

/* lg: 992 */
@media (max-width: 992px) { 

 }
 /* md: 768 */
@media (max-width: 768px) { 
  .lg-navbar{
    display: none;
  }
  .md-navbar{
    display: block;
  }
  .job-container{
    display: flex;
    flex-direction: column !important;
  }
  .job-company{
    width: 100% !important;
  }
  .job-description{
    width: 100% !important;
  }
  .pt-jumbotron{
    padding-top: 5rem;
  }
}
 /* sm: 576 */
 @media only screen and (max-width: 576px) {
  .sm-logo {
    display: none !important;
  }
  .pt-jumbotron{
    padding-top: 1rem;
  }
}
.job-container{
  width:100%;
  display: flex;
  flex-direction: row;
}
.job-company{
  width: 30%;
}
.job-description{
  width: 70%;
}

a.link-line {
  display: inline-block;
  position: relative;
  color: #408458;
  text-decoration: none;
}
a.link-line ::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  border-radius: 5px;
  height: 0.1em;
  bottom: 0;
  left: 0;
  background: currentcolor;
  transform-origin: bottom right;
  transition: transform 0.18s ease-out;
}
a.link-line :hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.link-dark-hover:hover{
  color: #408458 !important;
}
.md-navbar-popover{
 color: #408458;
  background-color: #222529;
  top:0;
  right: 0;
  height: 100vh;
  width: 100%;
 
}
.md-navbar-popover-content{
  padding: 20vh 0;
}
.cursor-pointer:hover{
  cursor: pointer !important;
}
.nav-popover-btn{
  color: white !important;
}
.nav-popover-btn:hover{
  font-weight: 800 !important;
}
.hover-btn-blue:hover{
  color: #82ABCA !important;
}
.hover-btn-purple:hover{
  color: #B782CA !important;
}
.hover-btn-green:hover{
  color: #6CA981 !important;
}
.hover-btn-yellow:hover{
  color: #BAC53C !important;
}
.close-btn{
  font-size: 2.25rem;
  color: lightgray;
}
.close-btn:hover{
  color:white;
}