@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap");
// Override default variables before the import
$link-color: #5b5c07;
$link-hover-color: #37380a;
:root {
	--font-family-sans-serif: "Noto Sans", -apple-system, BlinkMacSystemFont,
		Helvetica, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif,
		"Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
	--font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
		"Liberation Mono", "Courier New", monospace;
}
// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";
